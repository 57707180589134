import { useMemo, useState, useRef, useEffect } from "react";
import L from "leaflet";
import { MapContainer, TileLayer } from "react-leaflet";
import "leaflet-contextmenu";
import "leaflet-contextmenu/dist/leaflet.contextmenu.css";
import "@elfalem/leaflet-curve";
import { css } from "@emotion/css";
import { mapHelper } from "../../helpers";
import { Summary } from "./";

const style = {
  map: css`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 500px;
    overflow: hidden;
    position: relative;
    z-index: 8;
    border-radius: 16px;
    /* background: #000; */
    overflow: hidden;
    && {
      .leaflet-container {
        background: none;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        /* box-shadow: inset 0 0 3px 5px rgba(0, 0, 0, 0.1); */
      }
      .leaflet-pane.leaflet-tile-pane {
        /* opacity: 0.2; */
        /* filter: brightness(2); */
      }
      .leaflet-pane.leaflet-tile-pane .leaflet-layer {
        /* filter: saturate(0.5) brightness(3); */
        filter: saturate(0.6) brightness(1.1);
      }
      .leaflet-routing-alternatives-container {
        display: none;
      }
    }
    .custom-marker-pin {
      color: #fff;
      font-weight: bold;
      font-size: 12px;
      padding: 6px;
      text-align: center;
      position: relative;
      box-shadow: 1px -1px 0 0 rgb(0 0 0 / 30%);
      text-shadow: 0 0 1px #000;
      bottom: 42px;
      width: 55px;
      right: 20px;
      height: 55px;
      border-radius: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      position: relative;
      & > span {
        position: absolute;
        bottom: -1px;
        background: inherit;
        padding: 2px;
        border-radius: 50%;
      }
      &:before {
        position: absolute;
        bottom: -11px;
        right: 30px;
        content: "";
        width: 8px;
        height: 0px;
        border: 10px solid transparent;
        border-width: 5px 12px 12px 0;
        transform: rotate(23deg);
        border-right-color: #ff33cc;
        box-shadow: 1px 0 0 0 rgb(0 0 0 / 30%);
      }
      &.HA-marker-pin {
        background: #ff33cc;
        &:before {
          border-right-color: #ff33cc;
        }
      }
      &.HB-marker-pin {
        background: #ff9933;
        &:before {
          border-right-color: #ff9933;
        }
      }
      &.OH-marker-pin {
        background: #f00;
        &:before {
          border-right-color: #f00;
        }
      }
    }
    .start-end-pin {
      width: 10px;
      height: 10px;
      border: 2px solid #fff;
      border-radius: 50%;
      &.custom-end-pin {
        background-color: #fff;
      }
    }
    .leaflet-contextmenu {
      direction: rtl;
      & > * {
        cursor: pointer !important;
      }
    }
    label: travels-map;
  `,
};

const Map = ({
  tripSummary,
  trip,
  events,
  handleAddEvent,
  handleRemoveEvent,
  hasSummary,
}) => {
  const [map, setMap] = useState(null);
  const rMap = useRef();
  rMap.current = map;

  const [groupLayer, setGroupLayer] = useState(null);
  const rGroupLayer = useRef();
  rGroupLayer.current = groupLayer;

  const [eventGroupLayer, setEventGroupLayer] = useState(null);
  const rEventGroupLayer = useRef();
  rEventGroupLayer.current = eventGroupLayer;

  const [route, setRoute] = useState([]);
  const rRoute = useRef();
  rRoute.current = route;

  const [activeRoute, setActiveRoute] = useState(false);
  const rActiveRoute = useRef();
  rActiveRoute.current = activeRoute;

  const handleClickRoute = (event) => {
    handleAddEvent(event);
  };

  const drawRoute = ({ points, color = "#2FC2F0", index }) => {
    const totalPoints = points?.length;
    if (rMap.current && totalPoints) {
      const latestPoint = points[totalPoints - 1];
      const prevPoint = points[totalPoints - 2];

      // check if data is lost
      if (
        mapHelper?.getTimestampDifference(
          latestPoint?.timestamp,
          prevPoint?.timestamp
        ) > 3
      ) {
        setActiveRoute(false);

        var curvedPathShadow = mapHelper.routeCurvedPath({
          L,
          start: [prevPoint?.lat, prevPoint?.lng],
          end: [latestPoint?.lat, latestPoint?.lng],
          offset: 10,
          color: "black",
          opacity: 0.3,
          className: `trip-path trip-${index}`,
        });

        var curvedPath = mapHelper.routeCurvedPath({
          L,
          start: [prevPoint?.lat, prevPoint?.lng],
          end: [latestPoint?.lat, latestPoint?.lng],
          className: `trip-path trip-${index}`,
        });

        rMap.current.addLayer(curvedPathShadow);
        rMap.current.addLayer(curvedPath);
        setRoute([...rRoute.current, curvedPath]);
      } else {
        if (!rActiveRoute.current) {
          const routePoints = points
            .slice(Math.max(points.length - 2, 0))
            ?.map(({ lat, lng, timestamp }) => [lat, lng, timestamp]);
          if (routePoints.length > 1) {
            const newRouteLine = mapHelper.routeLine({
              L,
              routePoints,
              color,
              className: `trip-path trip-${index}`,
              handleClickRoute,
              contextmenu: !!handleAddEvent,
            });

            setRoute([...rRoute.current, newRouteLine]);
            setActiveRoute(true);
            newRouteLine.addTo(rMap.current);
          }
        } else {
          const currentRouteLine = rRoute?.current[rRoute?.current?.length - 1];
          currentRouteLine &&
            currentRouteLine.addLatLng(
              new L.LatLng(latestPoint?.lat, latestPoint?.lng)
            );
        }
      }
    }
  };

  const resetCustomLayers = () => {
    rGroupLayer.current.eachLayer(function (layer) {
      map?.removeLayer(layer);
    });
    setRoute([]);
    setActiveRoute(false);
    map?.panTo([40, -40]);
    map?.setZoom(4);
  };

  useEffect(() => {
    if (map) {
      rGroupLayer.current && resetCustomLayers();

      const index = 0;
      const points = trip?.points;
      const totalPoints = points?.length;

      if (points) {
        // set start/end markers
        const startLatlong = [points[0]?.lat, points[0]?.lng];
        const endtLatlong = [
          points[totalPoints - 1]?.lat,
          points[totalPoints - 1]?.lng,
        ];
        let startMarker = mapHelper.startEndMarker({
          L,
          type: "start",
        });
        let endMarker = mapHelper.startEndMarker({
          L,
          type: "end",
        });
        !!(startLatlong && startMarker) &&
          L.marker(startLatlong, { icon: startMarker }).addTo(rMap.current);
        !!(endtLatlong && endMarker) &&
          L.marker(endtLatlong, { icon: endMarker }).addTo(rMap.current);
      }

      points?.map((point, pointIndex) => {
        drawRoute({
          points: points.slice(0, pointIndex + 1),
          color: "#5857ff",
          index: index + 1,
        });
        return point;
      });

      setTimeout(() => {
        var layers = [];
        rMap.current.eachLayer((layer) => {
          if (
            layer instanceof L.Polyline ||
            layer instanceof L.Curve ||
            layer instanceof L.Marker
          ) {
            layers.push(layer);
          }
        });
        if (layers.length) {
          var group = new L.featureGroup(layers);
          setGroupLayer(group);

          rMap?.current?.fitBounds(group.getBounds(), {
            paddingTopLeft: [5, 5],
            paddingBottomRight: [hasSummary ? 265 : 5, 5],
          });
        }
      }, 50);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, trip]);

  const resetEventsLayer = () => {
    rEventGroupLayer.current.eachLayer(function (layer) {
      layer?.options?.icon?.options?.className !== "start-end-icon" &&
        map?.removeLayer(layer);
    });
  };

  useEffect(() => {
    if (map) {
      rEventGroupLayer.current && resetEventsLayer();
      // draw events
      events?.length &&
        events.map((event) => {
          const latlong = [event?.meta?.lat, event?.meta?.lng];
          let icon = mapHelper.eventMarker({
            L,
            type: event?.event_type,
            second: event?.second,
            className: `trip-path`,
            hasRemove: !!handleRemoveEvent,
          });

          !!(latlong && icon) &&
            L.marker(latlong, { icon })
              .on(
                "click",
                () => !!handleRemoveEvent && handleRemoveEvent(event)
              )
              .addTo(rMap.current);

          setTimeout(() => {
            var layers = [];
            rMap.current.eachLayer((layer) => {
              if (layer instanceof L.Marker) {
                layers.push(layer);
              }
            });
            if (layers.length) {
              var group = new L.featureGroup(layers);
              setEventGroupLayer(group);
            }
          }, 50);
          return null;
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, events]);

  useEffect(() => {
    return () => {
      rGroupLayer.current && resetCustomLayers();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const displayMap = useMemo(() => {
    return (
      <div className={style.map}>
        {hasSummary && <Summary tripSummary={tripSummary} />}
        <MapContainer
          center={[40, -40]}
          zoom={4}
          scrollWheelZoom={false}
          doubleClickZoom={false}
          zoomControl={false}
          dragging={false}
          ref={setMap}
          zoomSnap={0.25}
        >
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          {/* <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> &copy; <a href="http://cartodb.com/attributions">CartoDB</a>'
            url="https://cartodb-basemaps-{s}.global.ssl.fastly.net/dark_all/{z}/{x}/{y}.png"
          /> */}
        </MapContainer>
      </div>
    );
  }, [tripSummary, hasSummary]);

  return displayMap;
};

export default Map;
