import { css } from "@emotion/css";
import { ScoreBar } from "./";
import { mapHelper } from "../../helpers";

const style = {
  summary: css`
    position: absolute;
    top: 16px;
    right: 16px;
    width: 250px;
    bottom: 30px;
    z-index: 999;
    color: #fff;

    ul {
      font-size: 12px;
      list-style: none;
      padding: 0;
      margin: 0;
      li {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 10px 0;
        span {
          &.start-point,
          &.end-point {
            &:before {
              content: "";
              width: 6px;
              height: 6px;
              border-radius: 50%;
              border: 1px solid;
              display: inline-block;
              position: relative;
              right: -3px;
            }
          }
          &.end-point:before {
            background-color: #fff;
          }
        }
        strong {
          direction: ltr;
        }
      }
    }

    .summary-container {
      padding: 16px;
      direction: rtl;
      background: rgba(0, 0, 0, 0.5);
      backdrop-filter: blur(4px);
      border-radius: 8px;
      display: grid;
      grid-template-rows: auto 1fr auto;
      max-height: calc(100% - 32px);
      .score-container {
        h3 {
          margin: 0 0 8px;
          font-size: 14px;
          line-height: 1;
        }
        .score {
          display: flex;
          justify-content: space-between;
          align-items: center;
          span {
            font-size: 14px;
          }
          strong > span {
            font-size: 12px;
            opacity: 0.6;
          }
        }
      }
    }

    .events-container {
      width: 100%;
      padding: 2px 8px 2px 0;
      border-right: rgba(255, 255, 255, 0.6) 1px dashed;
      height: 100%;
      overflow-y: auto;
      ul {
        direction: rtl;
        width: calc(100% - 8px);
        li {
          background: rgba(255, 255, 255, 0.15);
          backdrop-filter: blur(4px);
          border-radius: 8px;
          padding: 0 0 0 8px;
          margin: 8px 0;

          & > div {
            display: grid;
            grid-template-columns: auto auto;
            grid-gap: 6px;

            .event-marker {
              border-radius: 4px;
              width: 20px;
              height: 20px;
              display: flex;
              justify-content: center;
              align-items: center;

              &.HA-marker {
                background-color: #ff33cc;
              }
              &.HB-marker {
                background-color: #ff9933;
              }
              &.OH-marker {
                background-color: #f00;
              }
            }
          }
        }
      }
    }

    label: summary;
  `,
};

const Summary = ({ tripSummary }) => {
  const totalHA = tripSummary?.events
    ? tripSummary.events?.filter(
        (event) => event?.type === "harsh acceleration"
      ).length
    : 0;
  const totalHB = tripSummary?.events
    ? tripSummary.events?.filter((event) => event?.type === "harsh braking")
        .length
    : 0;
  const totalHE = totalHA + totalHB * 3;
  const lostScore = totalHE <= 100 ? totalHE : 100;

  return tripSummary ? (
    <div className={style.summary}>
      <div className="summary-container">
        <div className="score-container">
          <h3>خلاصه سفر</h3>
          <ScoreBar ha={totalHA} hb={totalHB} />
          <div className="score">
            <span>امتیاز:</span>
            <strong>
              {100 - lostScore}
              <span>/100</span>
            </strong>
          </div>
          <ul className="summary-detail">
            <li>
              <span className="start-point">شروع:</span>
              <strong>{`${tripSummary?.start_trip?.lat}, ${tripSummary?.start_trip?.lng}`}</strong>
            </li>
          </ul>
        </div>
        {tripSummary?.events?.length && (
          <div className="events-container">
            <ul>
              {tripSummary.events.map((event, i) => {
                const type = mapHelper.getEventShortenType(event?.type);
                return (
                  <li key={`event-${i}`}>
                    <div>
                      <span className={`event-marker ${type}-marker`}>
                        <img
                          width={50}
                          height={50}
                          src={`/${type}.svg`}
                          alt={`${type}-marker`}
                        />
                      </span>
                      <span>{mapHelper.getEventTitle(event?.type)}</span>
                    </div>
                    <strong>
                      {mapHelper.getLocalTimeString(event?.meta?.timestamp)}
                    </strong>
                  </li>
                );
              })}
            </ul>
          </div>
        )}
        <ul className="summary-detail">
          <li>
            <span className="end-point">پایان:</span>
            <strong>{`${tripSummary?.end_trip?.lat}, ${tripSummary?.end_trip?.lng}`}</strong>
          </li>
          <li>
            <span>مدت:</span>
            <strong>{mapHelper.secToHMS(tripSummary?.duration)}</strong>
          </li>
          <li>
            <span>مسافت:</span>
            <strong>
              {Math.round(tripSummary?.distance + Number.EPSILON) / 1000} km
            </strong>
          </li>
          <li>
            <span>حداکثر سرعت:</span>
            <strong>{Math.round(tripSummary?.max_speed * 3.6)} km/h</strong>
          </li>
          <li>
            <span>میانگین سرعت:</span>
            <strong>{Math.round(tripSummary?.average_speed * 3.6)} km/h</strong>
          </li>
          <li>
            <span>مدت کارکرد درجا:</span>
            <strong>{mapHelper.secToHMS(tripSummary?.idle_time)}</strong>
          </li>
        </ul>
      </div>
    </div>
  ) : null;
};

export default Summary;
